import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import useStyles from '../pagesStyles/legalStyles';

import Layout from '../components/layout';
import Title from '../components/Title/index';
import RichTextRendererFromGatsby from '../components/RichTextRenderer/FromGatsby/index';

const LegalPage = ({data}) => {

  const classes = useStyles();
  const content = data.allContentfulMentionsLegales.nodes;

  return (
    <Layout>

      {/* SEO (do not use nofollow if you do not want it to be applied to all pages) */}
      <GatsbySeo noindex={true} />
      {/* <meta name="robots" content="noindex,follow">
      <meta name="googlebot" content="noindex,follow"> */}
      
      <Title 
      title={content[0].title}
      />
      
      <Box className={classes.margin}>
        <Typography variant="h2" className={classes.secondaryTitle}>{content[0].firstSubtitle}</Typography>
          <RichTextRendererFromGatsby details={content[0].paragraphe1} wrapperSize="smallWrapper" />
        <Typography variant="h2" className={classes.secondaryTitle}>{content[0].secondSubtitle}</Typography>
          <RichTextRendererFromGatsby details={content[0].paragraphe2} wrapperSize="smallWrapper" />
        <Typography variant="h2" className={classes.secondaryTitle}>{content[0].sousTitre3}</Typography>
          <RichTextRendererFromGatsby details={content[0].paragraphe3} wrapperSize="smallWrapper" />
      </Box>
    </Layout>
  )
  
}

export default LegalPage

export const query = graphql`
  query {
    allContentfulMentionsLegales {
      nodes {
        title
        firstSubtitle
        secondSubtitle
        sousTitre3
        paragraphe1 {
          raw
        }
        paragraphe2 {
          raw
        }
        paragraphe3 {
          raw
        }
      }
    }
  }
`
