import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(theme => ({
  secondaryTitle: {
    background: '#C45824', // Theme non reconnu dans les styles de pages
    padding: theme.spacing(3),
    margin: `${theme.spacing(3)}px 0`,
    textTransform: 'capitalize',
    textAlign: 'center'
  },

  margin: {
    paddingRight: '5%',
    paddingLeft: '5%',
  },
  text: {
    paddingBottom: '1%',
    '@media (max-width:570px)': {
        textAlign: 'justify'
    },
  } 
}));   